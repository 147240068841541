import { RULES } from '@grille/utils/validation'
import { Form } from '.'

export const MODAL_ENQUIRY: Form = {
  fields: [
    {
      labelClassName: '',
      placeholder: 'First name',
      name: 'firstName',
      type: 'text',
      hidden: false,
      rules: [
        {
          rule: RULES.isValidName,
          message: 'Please enter a first name'
        }
      ]
    },
    {
      labelClassName: '',
      placeholder: 'Last name',
      name: 'lastName',
      type: 'text',
      hidden: false,
      rules: [
        {
          rule: RULES.isValidName,
          message: 'Please enter a valid last name'
        }
      ]
    },
    {
      labelClassName: ' email',
      placeholder: 'Email address',
      name: 'enquiryEmail',
      type: 'text',
      hidden: false,
      rules: [{ rule: RULES.isEmail, minLength: 1, message: 'Please enter a valid email address' }]
    },
    {
      labelClassName: '',
      placeholder: 'Phone',
      name: 'enquiryPhone',
      type: 'text',
      hidden: true,
      rules: [{ rule: RULES.isMobilePhone, message: 'Please enter a valid phone number' }]
    },
    {
      labelClassName: '',
      placeholder: 'Postcode',
      name: 'enquiryPostcode',
      type: 'text',
      hidden: true,
      rules: [{ rule: RULES.isPostalCode, args: ['AU'], message: 'Please enter a valid postcode' }]
    },
    {
      placeholder: 'Comments',
      labelClassName: 'comments w-full',
      className: 'font-bold enquiry-comments',
      hidden: true,
      name: 'enquiryComments',
      type: 'textarea',
      rules: [{ rule: RULES.isValidComments, message: 'Please enter valid text' }]
    },
    {
      className: 'checkboxes mt-2 ',
      labelClassName: 'text-12px font-medium ',
      type: 'checkbox',
      header: "I'm also interested in:",
      hidden: false,
      name: 'enquiryCheckboxes',
      options: [
        {
          value: 'financeOffers',
          label: 'Finance options',
          name: 'financeCheckbox'
        },
        {
          value: 'newsletter',
          label: "Drive's newsletter",
          name: 'newsletterCheckbox'
        },
        {
          value: 'marketplace',
          label: 'Marketplace news & offers for new & used cars',
          name: 'marketplaceCheckbox'
        }
      ],
      rules: []
    }
  ],
  labels: [
    {
      id: 'enquiry-agree-label',
      name: 'enquiryAgreeLabel',
      hidden: false,
      className: 'text-white text-left mb-4 md:m-0 block md:inline-block w-full pr-4 self-center',
      label: `By clicking the Send Enquiry button you acknowledge that you have read and agree to the
      Drive <a style="color: inherit; text-decoration: underline;" target="_blank" href="/about-drive/terms-conditions/">Terms and Conditions </a>
      and <a style="color: inherit; text-decoration: underline;" target="_blank" href="/about-drive/privacy-policy/">Privacy Policy.</a>`
    }
  ],
  buttons: [
    {
      className: 'text-blue-dark text-15px font-semibold capitalize send-enquiry-btn',
      color: 'transparent',
      text: 'Send Enquiry',
      type: 'submit'
    }
  ]
}
